<template>
<div>
  <b-container>
    <b-row>
      <b-col>
        <div>
        <b-form @submit="search">
        <b-form-group horizontal class="mt-4">
          <b-input-group>
            <b-form-input v-model="q" placeholder="Type to search" />
              <b-btn :disabled="!q" @click="search">search</b-btn>
          </b-input-group>
        </b-form-group>
        </b-form>
        </div>
        <div>
          <b-form-group label="Sorting by">
            <b-form-radio-group
              v-model="sortingSelected"
              :options="sortingOptions"
              name="radio-sorting"
            ></b-form-radio-group>
          </b-form-group>
        </div>
      </b-col>
    </b-row>
    <b-row class="mb-4" v-if="!loading">
      <b-col>
        <b-card class="mb-3" v-for="item in news" :key="item.id" :id="item.id">
          <publication
            :propItem="item"
            :key="item.id"
            :tagsProp=tags
            :username="user.username"
            :permissionAddArticle="permissionAddArticle"
            :permissionAddRisk="permissionAddRisk"
            :permissionAddTag="permissionAddTag"
            :permissionArticlesWip="permissionArticlesWip"
            :permissionDeletePublication="permissionDeletePublication"
            :permissionInsights="permissionInsights"
            :permissionReadTagsPanel="permissionReadTagsPanel"
             />
        </b-card>
      </b-col>
    </b-row>
    <b-row v-if="loading" class="text-center mb-4" align-h="center">
      <b-col cols="1">
        <div><i class="fa fa-spinner fa-pulse fa-lg fa-fw"></i></div>
      </b-col>
    </b-row>
  </b-container>
</div>
</template>

<script>
import ac from '../libs/accesscontrol'
import Publication from '@/components/PublicationNew'

export default {
  components: {
    Publication
  },
  computed: {
    user: {
      get () {
        return this.$store.state.user
      }
    }
  },
  created: async function () {
    this.permissionAddArticle = ac.can(this.user.acgroups).createAny('article').granted
    this.permissionAddRisk = ac.can(this.user.acgroups).createAny('marrisk').granted
    this.permissionAddTag = ac.can(this.user.acgroups).createAny('newstag').granted
    this.permissionArticlesWip = ac.can(this.user.acgroups).createAny('articleWorkflowEdit').granted
    this.permissionDeletePublication = ac.can(this.user.acgroups).deleteAny('publication').granted
    this.permissionInsights = true
    this.permissionReadAnalytics = ac.can(this.user.acgroups).readAny('analytics').granted
    this.permissionReadTagsPanel = ac.can(this.user.acgroups).readAny('tagspanel').granted
    if (this.$config.BUILD === 'external') {
      this.permissionAddRisk = false
      this.permissionAddTag = false
      this.permissionDeletePublication = false
      this.permissionInsights = ac.can(this.user.acgroups).readAny('article').granted
      this.permissionReadAnalytics = false
      this.permissionReadTagsPanel = false
    }
    try {
      let apiName = 'cosmos'
      let path = `/tags`
      this.tags = await this.$Amplify.API.get(apiName, path)
    } catch (e) {
      this.$logger.warn('error getting events for a day', e)
    }
    if (this.$route.query.o) {
      if (this.$route.query.o === 'time') {
        this.sortingSelected = 'time'
      } else {
        this.sortingSelected = 'rank'
      }
    } else {
      const options = window.localStorage.getItem('frmCloudSearchSorting')
      if (options) {
        this.sortingSelected = JSON.parse(options)
      }
    }
    this.load()
  },
  data () {
    return {
      authToken: '',
      gridLoaderColor: 'black',
      gridLoaderSize: '10px',
      loading: true,
      news: [],
      permissionAddArticle: false,
      permissionAddRisk: false,
      permissionAddTag: false,
      permissionArticlesWip: false,
      permissionDeletePublication: false,
      permissionReadAnalytics: false,
      permissionReadTagsPanel: false,
      sortingOptions: [
        { text: 'By rank', value: 'rank' },
        { text: 'By date', value: 'time' }
      ],
      sortingSelected: 'rank',
      q: '',
      tags: []
    }
  },
  methods: {
    load: async function () {
      this.loading = true
      this.q = this.$route.params.q
      this.$logger.debug('load started')
      try {
        let apiName = 'cosmos'
        let path = ''
        if (Number.isInteger(Number(this.q))) {
          path = `/news/item/${this.q}`
          let response = await this.$Amplify.API.get(apiName, path)
          this.$logger.debug(response)
          this.news = [response]
        } else {
          path = `/search/${this.q}?o=${this.sortingSelected}`
          let response = await this.$Amplify.API.get(apiName, path)
          this.$logger.debug(response)
          this.news = response
        }
        this.loading = false
        this.$stat.log({ page: 'search', action: 'searched', payload: { term: this.q, sorting: this.sortingSelected } })
      } catch (e) {
        this.$logger.warn('saved ERROR: ' + e)
      }
    },
    search: function (evt) {
      evt.preventDefault()
      this.$router.push({ name: 'Search', params: { q: this.q }, query: { o: this.sortingSelected } })
    },
    updateSorting: function () {
      this.$router.push({ name: 'Search', params: { q: this.q }, query: { o: this.sortingSelected } })
      window.localStorage.setItem('frmCloudSearchSorting', JSON.stringify(this.sortingSelected))
    }
  },
  watch: {
    '$route.params.q': 'load',
    '$route.query.o': 'load',
    'sortingSelected': 'updateSorting'
  }
}
</script>

<style>
</style>
